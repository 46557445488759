import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'

import "../scss/pages/index.scss"

class Article extends React.Component {
  render() {
    const { location, data } = this.props

	console.log(this.props);
    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Articling" />
        <div>
			<section className="hero article" style={{backgroundImage: "url('/img/article-header.jpg')"}}>
				<div className="container pb-5 py-md-5">
					<div className="row short-top-hero align-items-end">
						<div className="col-md-10 d-flex justify-content-between align-items-end">
							<h1 className="h1 pt-md-5 pl-3 pl-md-4 mb-0">Articling</h1>							
						</div>
						<div className="col-md-2 text-right">			
							<Link className="mr-3" to="/contact/"><img className="icon" src="/img/icons/white-whatsapp.png" alt=""/></Link>
							<a target="_blank" href="https://twitter.com/GHW_barristers"><img className="icon" src="/img/icons/white-twitter.png" alt=""/></a><br/>	
						</div>						
					</div>
				</div>
			</section>
			<section className="bg-black fade-black-top pb-5">
				<div className="container bg-white mb-5">
					<div className="row">
						<div className="col-md-12 py-5 px-5">
							<div className="row">
								<div className="col-md-9 pr-5">
									<p>Articling students are an integral part of the team at Greenspan Humphrey Weinstein LLP.  Students work closely with all of our partners and associates, and are exposed to all aspects of the practice of criminal law. We endeavour to ensure that each student has a well-rounded articling year, with diverse assignments on both appeal and trial files.</p>
									<p>Our articling students attend court regularly to speak to routine administrative matters.  In addition, students have liberal opportunities and are encouraged to accompany our lawyers to court to observe all aspects of criminal proceedings.  </p>
									<p>We recruit one or two students for each licensing cycle and strictly adhere to the recruitment procedures defined by the Law Society of Ontario.  Interested candidates are encouraged to electronically submit a covering letter, curriculum vitae, academic transcripts, writing sample and letters of reference.  </p>
									<p>For more information about our student program, or to submit an application, please contact Jill Makepeace.</p>
								</div>
								<div className="col-md-3">
									<h4 className="text-primary">Get in touch</h4>
									<p className="text-primary">15 Bedford Rd.<br/>
									Toronto, Ontario <br/>
									M5R 2J7</p>
									<p><a href="tel:416-868-1755,4232">416-868-1755, Ext: 4232</a></p>
									<p><a href="mailto:jmakepeace@15bedford.com">jmakepeace@15bedford.com</a></p>
									<p><a href="mailto:sg@15bedford.com">Contact Jill’s Assistant</a></p>									
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
        </div>
      </Layout>
    )
  }
}

export default Article

